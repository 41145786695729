import { forwardRef } from 'react'
import { CircularProgress, Modal } from '@material-ui/core'

const Loader = forwardRef((props, ref) => (
  <div
    {...props}
    ref={ref}
    css={{
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress size={50} />
  </div>
))

export const DialogLoader = () => (
  <Modal open>
    <Loader />
  </Modal>
)

export default Loader
