import { lazy, Suspense } from 'react'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import ErrorBoundary from '../components/ErrorBoundary'
import Meta from '../components/Meta'
import AuthRoute from '../components/AuthRoute'
import Loader from '../components/App/Loader'
import ModalSwitch from '~/components/ModalSwitch'
import getUserConfirmation from '~/lib/getUserConfirmation'
import SnackbarProvider from '~/lib/SnackbarProvider'
import CompaniesBillingDashboard from '../containers/Companies/billing'

const ProfileSettings = lazy(() => import('../containers/Profile/Settings'))
const Dashboard = lazy(() => import('../containers/Dashboard'))
const Projects = lazy(() => import('../containers/Projects'))
const Depots = lazy(() => import('../containers/Depots'))
const Depot = lazy(() => import('../containers/Depot'))
const Users = lazy(() => import('../containers/Users'))
const User = lazy(() => import('../containers/User'))
const Project = lazy(() => import('../containers/Project'))
const Companies = lazy(() => import('../containers/Companies'))
const Company = lazy(() => import('../containers/Company'))
const CompanyFleet = lazy(() => import('../containers/Fleet/CompanyFleet'))
const ItemSource = lazy(() => import('../containers/ItemSource'))
const ProjectUser = lazy(() => import('../containers/ProjectUser'))
const ProjectUserNotificationsPreview = lazy(() =>
  import('../containers/ProjectUser/ProjectUserNotificationsPreview'),
)
const Login = lazy(() => import('../containers/Login'))
const Logout = lazy(() => import('../containers/Logout'))
const NoMatch = lazy(() => import('../containers/NoMatch'))
const GlobalItems = lazy(() => import('../containers/GlobalItems'))
const MoveItemsGlobalItem = lazy(() =>
  import('../containers/GlobalItems/MoveItems'),
)
const DataSources = lazy(() => import('../containers/DataSources'))
const Transport = lazy(() => import('../containers/Transport'))
const Transports = lazy(() => import('../containers/Transports'))
const DataSource = lazy(() => import('../containers/DataSource'))
const SyncLog = lazy(() => import('../containers/SyncLog'))
const ItemSourcesThatNeedAttention = lazy(() =>
  import('../containers/ItemSourcesThatNeedAttention'),
)
const MixedUpItemsCandidates = lazy(() =>
  import('../containers/MixedUpItemsCandidates'),
)
const Compare = lazy(() => import('../containers/Compare'))
const Tools = lazy(() => import('../containers/Tools'))
const WorkerQueueTestingTool = lazy(() =>
  import('../containers/WorkerQueueTestingTool'),
)
const SearchProjectItems = lazy(() =>
  import('../containers/SearchProjectItems'),
)
const CostsExplorer = lazy(() => import('../containers/CostsExplorer'))
const DuplicatesFinder = lazy(() => import('../containers/DuplicatesFinder'))

const Sheets = lazy(() => import('../containers/Sheets'))
const ProjectsWithProjectOwned = lazy(() =>
  import('../containers/ProjectsWithProjectOwned'),
)

const DataSourceCustomer = lazy(() =>
  import('../containers/DataSourceCustomer'),
)
const UsersMemberMultipleCompanies = lazy(() =>
  import('../containers/UsersMemberMultipleCompanies'),
)
const ManageInactiveUsers = lazy(() =>
  import('../containers/ManageInactiveUsers'),
)
const ManagePhoneNumbers = lazy(() =>
  import('../containers/ManagePhoneNumbers'),
)
const ItemSourcesPublicNotes = lazy(() =>
  import('../containers/ItemSourcesPublicNotes'),
)

const WelcomeLayout = lazy(() => import('../components/Layout/WelcomeLayout'))
const AdminLayout = lazy(() => import('../components/Layout/AdminLayout'))
const SettingsLayout = lazy(() => import('../components/Layout/SettingsLayout'))

const App = () => {
  if (typeof window === 'undefined') {
    return null
  }
  return (
    <SnackbarProvider>
      <Suspense fallback={<Loader />}>
        <ErrorBoundary>
          <Router getUserConfirmation={getUserConfirmation}>
            <Meta />
            <ModalSwitch>
              <Route path="/profile/settings">
                <SettingsLayout>
                  <Suspense fallback={<Loader />}>
                    <AuthRoute
                      exact
                      path="/profile/settings"
                      component={ProfileSettings}
                    />
                  </Suspense>
                </SettingsLayout>
              </Route>
              <Route path="/logout">
                <WelcomeLayout>
                  <Route path="/logout" component={Logout} />
                </WelcomeLayout>
              </Route>
              <Route path="/login">
                <WelcomeLayout>
                  <AuthRoute path="/login" component={Login} />
                </WelcomeLayout>
              </Route>
              <AuthRoute path="/">
                <AdminLayout>
                  <Suspense fallback={<Loader />}>
                    <Switch>
                      <Route exact path="/" component={Dashboard} />
                      <Route exact path="/users" component={Users} />
                      <Route exact path="/users/:userId" component={User} />
                      <Route exact path="/depots" component={Depots} />
                      <Route path="/depots/:depotId" component={Depot} />
                      <Route exact path="/transports" component={Transports} />
                      <Route
                        exact
                        path="/transports/:transportId"
                        component={Transport}
                      />
                      <Route exact path="/projects" component={Projects} />
                      <Route
                        path="/projects/:projectId/item-sources/:itemSourceId"
                        component={ItemSource}
                      />
                      <Route
                        path="/projects/:projectId/users/:userId/notificationspreview"
                        component={ProjectUserNotificationsPreview}
                      />
                      <Route
                        path="/projects/:projectId/users/:userId"
                        component={ProjectUser}
                      />
                      <Route path="/projects/:projectId" component={Project} />
                      <Route exact path="/companies" component={Companies} />
                      <Route
                        exact
                        path="/companies/billing"
                        component={CompaniesBillingDashboard}
                      />
                      <Route path="/companies/:companyId" component={Company} />
                      <Route
                        path="/fleet-companies/:companyId"
                        component={CompanyFleet}
                      />
                      <Route
                        path="/data-sources/:dataSourceId/customers/:dataSourceCustomerId"
                        component={DataSourceCustomer}
                      />
                      <Route
                        path="/data-sources/:dataSourceId"
                        component={DataSource}
                      />
                      <Route
                        exact
                        path="/data-sources"
                        component={DataSources}
                      />
                      <Route
                        exact
                        path="/global-items"
                        component={GlobalItems}
                      />
                      <Route
                        exact
                        path="/global-items/:globalItemId"
                        component={MoveItemsGlobalItem}
                      />
                      <Route exact path="/sync-log" component={SyncLog} />
                      <Route
                        exact
                        path="/equipment-matchings-that-need-attention"
                        component={ItemSourcesThatNeedAttention}
                      />
                      <Route
                        exact
                        path="/mixed-up-items-candidates"
                        component={MixedUpItemsCandidates}
                      />
                      <Route
                        exact
                        path="/worker-queue-testing-tool"
                        component={WorkerQueueTestingTool}
                      />
                      <Route
                        exact
                        path="/costs-explorer"
                        component={CostsExplorer}
                      />
                      <Route
                        exact
                        path="/duplicates-finder"
                        component={DuplicatesFinder}
                      />
                      <Route
                        exact
                        path="/all-users-member-multiple-companies"
                        component={UsersMemberMultipleCompanies}
                      />
                      <Route
                        exact
                        path="/manage-inactive-users"
                        component={ManageInactiveUsers}
                      />
                      <Route
                        exact
                        path="/manage-phone-numbers"
                        component={ManagePhoneNumbers}
                      />
                      <Route
                        exact
                        path="/item-sources/public-notes"
                        component={ItemSourcesPublicNotes}
                      />
                      <Route
                        exact
                        path="/search-project-items"
                        component={SearchProjectItems}
                      />
                      <Route exact path="/tools" component={Tools} />
                      <Route exact path="/compare" component={Compare} />
                      <Route exact path="/sheets" component={Sheets} />
                      <Route
                        exact
                        path="/projects-with-project-owned"
                        component={ProjectsWithProjectOwned}
                      />
                      <Route component={NoMatch} />
                    </Switch>
                  </Suspense>
                </AdminLayout>
              </AuthRoute>
              <WelcomeLayout>
                <Route component={NoMatch} />
              </WelcomeLayout>
            </ModalSwitch>
          </Router>
        </ErrorBoundary>
      </Suspense>
    </SnackbarProvider>
  )
}

export default App
