import { Helmet } from 'react-helmet-async'

const Meta = ({ title }) => {
  const formattedTitle = `${title || 'SuperAdmin'} - Infobric Equipment`
  return (
    <Helmet>
      <title>{formattedTitle}</title>
    </Helmet>
  )
}

export default Meta
