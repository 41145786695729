const getUserConfirmation = (dialogKey, callback) => {
  // use "message" as Symbol-based key
  const dialogTrigger = window[Symbol.for(dialogKey)]

  if (dialogTrigger) {
    // pass the callback to delegate
    // to the invoked dialog
    return dialogTrigger(callback)
  }

  // Fallback to allowing navigation
  callback(window.confirm(dialogKey))
}

export default getUserConfirmation
