import { lazy, Suspense } from 'react'
import { Dialog, CircularProgress, IconButton } from '@material-ui/core'
import { Close as CloseIcon } from 'mdi-material-ui'
import { orange } from '~/lib/colors'
const ItemCard = lazy(() => import('./Card'))

const ItemDialog = ({ history, location, match }) => {
  const onClose = () => {
    if (location.state) {
      const { state } = location
      if (state.from) {
        return history.push(state.from)
      }
    }

    if (history.length > 2) {
      return history.goBack()
    }

    history.replace('/')
  }
  return (
    <Dialog
      onClose={onClose}
      open
      scroll="body"
      fullWidth
      maxWidth="sm"
      css={{
        '.paper': {
          overflow: 'visible',
          width: 'initial',
        },
      }}
      classes={{ paperFullWidth: 'paper' }}
    >
      <IconButton
        onClick={onClose}
        style={{
          top: '10px',
          right: '10px',
          position: 'absolute',
        }}
      >
        <CloseIcon style={{ color: orange[500] }} />
      </IconButton>
      <Suspense fallback={<CircularProgress />}>
        <ItemCard {...match.params} />
      </Suspense>
    </Dialog>
  )
}
export default ItemDialog
