import { useRef } from 'react'
import { Column, Table, AutoSizer } from 'react-virtualized'
import 'react-virtualized/styles.css' // only needs to be imported once

import useScrollTopOnChange from './lib/useScrollTopOnChange'
import { css } from '@emotion/react'
import defaultHeaderRenderer from './lib/headerRenderer'
const containerCss = css`
  height: 100%;
  .ReactVirtualized__Table__headerColumn:focus {
    outline: none;
  }
  .ReactVirtualized__Table__headerColumn:focus .focusable {
    text-decoration: underline;
  }
  .ReactVirtualized__Grid:focus {
    outline: none;
  }

  .cell a {
    color: inherit;
  }

  .row:focus {
    outline: none;
  }

  .row:not(.row--header) {
    border-bottom: #f1f1f1 1px solid;

    background: white;
  }

  .row:not(.row--header):hover {
    background: rgba(0, 0, 0, 0.05);
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .row:not(.row--header):hover {
      background: none;
    }
  }

  .loader {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    border-radius: 3px;
    padding: 4px 8px;
    color: white;
    font-size: 13px;
    bottom: 20px;
    right: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cell {
    /* border-left: #e9eef2 1px solid; */
    padding-left: 10px;
    height: 100%;
    line-height: 48px;
    font-size: 13px;
  }

  .cell--checkbox {
    overflow: visible !important;
  }

  .row .cell:first-of-type {
    border-left: none;
  }

  .row--header {
    border-top: #d8d8d8 1px solid;
    border-bottom: #d8d8d8 1px solid;
  }

  .cell--header {
    color: rgba(0, 0, 0, 0.54);
    display: flex;
    font-weight: 500;
    align-items: center;
    text-transform: none;
    user-select: none;
  }

  .no-rows {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
`

const rowClassName = ({ index }) => {
  if (index < 0) {
    return 'row row--header'
  } else {
    return index % 2 === 0 ? 'row row--even' : 'row row--odd'
  }
}

const DataTable = (props) => {
  const {
    data = [],
    columns,
    fetchNextPage,
    loading,
    pageInfo,
    variables,
    noRowsRenderer,
    onScroll,
    sortOrder,
    reverseSort,
    onSort,
    onRowClick,
    rowRenderer,
  } = props

  const tableRef = useRef(null)
  useScrollTopOnChange(tableRef, variables)

  const handleScroll = ({ clientHeight, scrollHeight, scrollTop }) => {
    if (!fetchNextPage || (pageInfo && !pageInfo.hasNextPage) || loading) return

    const scrollPos = clientHeight + scrollTop
    const loadThreshold = Math.abs(scrollHeight - 1000)

    if (scrollPos > (scrollHeight / 3) * 2 || scrollPos > loadThreshold) {
      fetchNextPage()
    }
  }

  const handleSort = onSort
    ? (props) => {
        const { sortBy, sortDirection } = props

        onSort({
          sortOrder: sortBy,
          reverseSort: sortDirection !== 'ASC',
        })
      }
    : null

  const renderedColumns = columns.map(({ sortable = false, ...columnData }) => {
    const disableSort = !onSort || sortable === false
    return (
      <Column
        key={columnData.dataKey}
        columnData={{
          tooltip: columnData.tooltip,
          sortable,
        }}
        disableSort={disableSort}
        headerRenderer={defaultHeaderRenderer}
        className={
          columnData.dataKey === 'checkbox' ? 'cell cell--checkbox' : 'cell'
        }
        headerClassName="cell cell--header"
        {...columnData}
      />
    )
  })
  return (
    <div css={containerCss}>
      <AutoSizer>
        {({ height, width }) => (
          <Table
            ref={tableRef}
            width={width}
            height={height}
            headerHeight={42}
            rowHeight={48}
            rowCount={data.length}
            rowGetter={({ index }) => data[index]}
            onScroll={onScroll || handleScroll}
            noRowsRenderer={noRowsRenderer}
            sort={handleSort}
            sortBy={
              sortOrder
                ? sortOrder.replace(/(_\w)/g, function (m) {
                    return m[1].toUpperCase()
                  })
                : sortOrder
            }
            sortDirection={reverseSort ? 'DESC' : 'ASC'}
            rowClassName={rowClassName}
            onRowClick={onRowClick}
            rowRenderer={rowRenderer}
          >
            {renderedColumns}
          </Table>
        )}
      </AutoSizer>
    </div>
  )
}

export default DataTable
