import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import { getContinueUrl } from '~/lib/helpers'

import Loader from './App/Loader'
import LogoutButton from './Layout/AdminLayout/AppBar/LogoutButton'

export const IS_LOGGED_IN = gql`
  query isLoggedIn {
    getUser {
      id
      name
      initials
      firstName
      lastName
      gdprConfirmed
      isSuperAdmin
      profilePic(settings: { width: 100, height: 100 }) {
        id
        url
      }
    }
  }
`

const RedirectToEqlLogin = (props) => {
  const search = props.search

  useEffect(() => {
    window.location = process.env.GATSBY_EQL_LOGIN + search
  }, [search])
  return null
}

export default function AuthRoute({ component: Component, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Query query={IS_LOGGED_IN}>
            {({ data, loading, error }) => {
              if (loading || !data) return <Loader />
              const user = data.getUser

              if (user && user.id) {
                Sentry.configureScope((scope) => {
                  scope.setUser({ id: user.id })
                })
              } else {
                Sentry.configureScope((scope) => {
                  scope.setUser({ id: null })
                })
              }

              if (props.location.pathname === '/login') {
                if (!user) {
                  return <Component {...props} />
                }

                return (
                  <Redirect
                    to={{
                      pathname: '/',
                      state: { from: props.location },
                    }}
                  />
                )
              }

              if (!user) {
                return (
                  <RedirectToEqlLogin search={getContinueUrl(props.location)} />
                )
              }

              if (user && user.isSuperAdmin !== true) {
                return (
                  <div
                    style={{
                      background: '#ccc',
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    503 Not authorized <LogoutButton />
                  </div>
                )
              }

              if (children && !Component) return children
              return <Component {...props} />
            }}
          </Query>
        )
      }}
    />
  )
}
