import Wrapper from '../../AdminLayout/Wrapper'
import { Toolbar, Typography } from '@material-ui/core'
import { NavLink as Link } from 'react-router-dom'
import { css } from '@emotion/react'

import Meta from '~/components/Meta'

const Breadcrumbs = ({ items }) => (
  <Toolbar
    variant="dense"
    css={css`
      a {
        color: #000;
        margin-right: 20px;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        &:after {
          content: '>';
          text-decoration: none;
          pointer-events: none;
          position: absolute;
          width: 20px;
          text-align: center;
        }
        &:last-of-type {
          &:after {
            display: none;
          }
        }

        &.active {
          text-decoration: none;
        }
      }
    `}
  >
    {items.map(({ to, label }, index) => (
      <Link
        exact={index !== items.length - 1}
        activeClassName="active"
        key={label}
        to={to}
      >
        {label}
      </Link>
    ))}
  </Toolbar>
)

const PageHeader = ({
  title,
  count,
  leftToolbar,
  toolbar,
  breadcrumbs,
  fullWidthToolbar,
}) => {
  return (
    <Wrapper slim style={{ overflow: 'visible', padding: '20px' }}>
      {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
      {title && <Meta title={title} />}

      <Toolbar variant="dense">
        <Typography variant="h5">
          {title} {count ? `(${count})` : null}
        </Typography>

        {leftToolbar && leftToolbar}
        {!fullWidthToolbar && <div style={{ flex: 1 }} />}
        {toolbar}
      </Toolbar>
    </Wrapper>
  )
}

export default PageHeader
