import { useEffect } from 'react'
import usePrevious from '~/lib/usePrevious'
import deepEqual from 'fast-deep-equal'

const useScrollTopOnChange = (tableRef, variables) => {
  const prevVariables = usePrevious(variables)
  useEffect(() => {
    if (prevVariables && variables && tableRef && tableRef.current) {
      if (!deepEqual(variables, prevVariables)) {
        tableRef.current.scrollToPosition(0)
      }
    }
  }, [prevVariables, tableRef, variables])
}

export default useScrollTopOnChange
