const resolvers = new Map([
  [
    'pwa-construction',
    new Map([
      ['stage', 'https://app.equipmentloop.tech'],
      ['prod', 'https://infobricequipment.app'],
      ['local', 'http://localhost:3007'],
    ]),
  ],
  [
    'pwa-rental',
    new Map([
      ['stage', 'https://rental.equipmentloop.tech'],
      ['prod', 'https://rental.infobricequipment.app'],
      ['local', 'http://localhost:3004'],
    ]),
  ],
  [
    'pwa-admin',
    new Map([
      ['stage', 'https://superadmin.equipmentloop.tech'],
      ['prod', 'https://superadmin.infobricequipment.app'],
      ['local', 'http://localhost:3005'],
    ]),
  ],
  [
    'frontend-company',
    new Map([
      ['stage', 'https://admin.equipmentloop.tech'],
      ['prod', 'https://company.infobricequipment.app'],
      ['local', 'http://localhost:3003'],
    ]),
  ],
  [
    'service-rental-data-importer',
    new Map([
      [
        'stage',
        'http://rental-data-importer-stage.eu-north-1.elasticbeanstalk.com',
      ],
      [
        'prod',
        'http://rental-data-importer-prod.eu-north-1.elasticbeanstalk.com',
      ],
      ['local', 'http://localhost:3009'],
    ]),
  ],
])

const getEnv = () => {
  const origin = window.location.origin
  if (!origin) throw new Error('Cant find origin')
  if (origin.includes('localhost')) {
    return 'local'
  }
  if (origin.includes('.se') || origin.includes('infobricequipment.app')) {
    return 'prod'
  }
  if (
    origin.includes('.tech') ||
    origin.includes('.dev') ||
    origin.includes('.now.sh') ||
    origin.includes('.vercel.app')
  ) {
    return 'stage'
  }
}

export default (appName) => {
  if (!resolvers.has(appName)) {
    throw new Error(`No resolver for "${appName}"`)
  }
  const env = getEnv()
  const resolver = resolvers.get(appName)
  if (!resolver.has(env)) {
    throw new Error(`No resolver for "${appName}@${env}"`)
  }

  return resolver.get(env)
}
