import { createRef } from 'react'
import { Button } from '@material-ui/core'

import { SnackbarProvider } from 'notistack'

const EQLSnackbarProvider = ({ children }) => {
  const notistackRef = createRef()
  return (
    <SnackbarProvider
      disableWindowBlurListener
      ref={notistackRef}
      classes={{ variantSuccess: 'snackbar--success' }}
      action={(key) => (
        <Button
          style={{ color: '#fff' }}
          size="small"
          onClick={() => notistackRef.current.closeSnackbar(key)}
        >
          <b>OK</b>
        </Button>
      )}
    >
      {children}
    </SnackbarProvider>
  )
}

export default EQLSnackbarProvider
