import { useState } from 'react'
import DataTable from '~/components/DataTable'
import { Link } from 'react-router-dom'
import sortOn from 'sort-on'
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { DotsVertical as MenuIcon } from 'mdi-material-ui'
import resolveUrl from '~/lib/resolve-url'
import clsx from 'clsx'
import moment from 'moment'

const getCompanyPathname = ({ companyId }) => {
  return `/companies/${companyId}/edit`
}

const useColumns = () => {
  const columns = [
    {
      dataKey: 'name',
      label: 'Name',
      cellRenderer: ({ rowData: company, cellData: label }) => (
        <div className="flex items-center">
          {company?.logo?.url && (
            <img
              className="mr-2 h-6 object-contain"
              alt=""
              src={company?.logo?.url}
            />
          )}
          <Link
            to={{
              pathname: getCompanyPathname({
                companyId: company.id,
              }),
            }}
          >
            {company?.name}
          </Link>
        </div>
      ),
      width: 100,
      flexGrow: 1,
      sortable: true,
    },
    {
      dataKey: 'createdAt',
      label: 'Created At',
      type: 'date',
      cellRenderer: ({ cellData }) => {
        if (!cellData) return null
        return (
          <span title={moment(cellData).format('YYYY-MM-DD HH:mm')}>
            {moment(cellData).fromNow()}
          </span>
        )
      },
      width: 100,
      sortable: true,
    },
    {
      dataKey: 'createdBy.name',
      label: 'Created By',
      cellRenderer: ({ rowData }) => {
        const { createdBy } = rowData
        if (!createdBy) return null
        return (
          <Link
            to={{
              pathname: `/users/${createdBy.id}`,
            }}
          >
            {createdBy.name || createdBy.email || 'UNKNOWN'}
          </Link>
        )
      },
      width: 100,
      sortable: true,
    },

    {
      dataKey: 'billing.equipment.pricing.current.title',
      label: 'Current plan',
      cellRenderer: ({ rowData }) => {
        if (!rowData?.billingEnabled)
          return <b className="text-red-500">Disabled</b>
        const current = rowData?.billing?.equipment?.pricing?.current
        if (!current) return 'None'

        const color = {
          BASIC: 'text-primary-light  border-primary-light',
          PRO: 'text-primary-main  border-primary-main',
          BUSINESS: 'text-primary-main  border-primary-main',
          ENTERPRISE: 'text-red-500  border-red-500',
        }
        return (
          <span
            className={clsx(
              'uppercase rounded border border-solid px-1',
              color[current.type] ||
                'text-secondary-main  border-secondary-main',
            )}
          >
            {current.title}
          </span>
        )
      },

      width: 150,
      sortable: true,
    },
    {
      dataKey: 'onboardingChecklist.progress',
      label: 'Onboarding',
      cellRenderer: ({ rowData }) => {
        if (!rowData?.onboardingEnabled) return 'Disabled'
        const progress = `${Math.round(
          rowData?.onboardingChecklist?.progress * 100,
        )}%`
        return (
          <Tooltip
            title={
              <div>
                {rowData?.onboardingChecklist?.steps?.map((step) => {
                  return (
                    <div>
                      {step.isSkipped
                        ? `✅ (skipped)`
                        : step.isComplete
                        ? '✅'
                        : '❌'}{' '}
                      - {step.type}
                    </div>
                  )
                })}
              </div>
            }
          >
            <span
              className="underline"
              style={{
                textDecorationStyle: 'dotted',
                cursor: 'progress',
              }}
            >
              {progress}
            </span>
          </Tooltip>
        )
      },
      width: 120,
      sortable: true,
    },
    {
      dataKey: 'companyUsersCount',
      label: 'Users',
      width: 80,
      sortable: true,
    },
    {
      dataKey: 'placesCount',
      label: 'Places',
      width: 80,
      sortable: true,
    },
    {
      dataKey: 'itemsCount',
      label: 'Equipment',
      width: 100,
      sortable: true,
    },

    {
      dataKey: 'billing.nextInvoice.amount',
      label: 'Next invoice',
      cellDataGetter: ({ rowData }) => {
        const current = rowData?.billing?.nextInvoice
        if (!current) return 'None'

        return `${current.amount}`
      },

      width: 150,
      sortable: true,
    },
    {
      dataKey: 'billing.invoiceDetails.email',
      label: 'Invoice details',
      cellRenderer: ({ rowData }) => {
        const current = rowData?.billing?.invoiceDetails

        if (!current?.email) {
          if (rowData?.billing?.nextInvoice) return '❌'
          return 'N/A'
        }

        const reference = current?.reference || 'N/A'
        const name = current?.name || 'N/A'
        const email = current?.email || 'N/A'
        const country = rowData?.country || 'N/A'
        const orgNo = rowData?.organizationNumber || 'N/A'

        return (
          <Tooltip
            interactive
            title={
              <div>
                <div>Name: {name}</div>
                <div>Email: {email}</div>
                <div>Reference: {reference}</div>
                <div>Country: {country}</div>
                <div>OrgNo: {orgNo}</div>
              </div>
            }
          >
            <span>{current.email}</span>
          </Tooltip>
        )
      },

      width: 150,
      sortable: true,
    },
    // {
    //   dataKey: 'country',
    //   label: 'Country',
    //   cellDataGetter: ({ rowData }) => rowData?.country,
    //   width: 100,
    //   sortable: true,
    // },
    // {
    //   dataKey: 'organizationNumber',
    //   label: 'OrgNo',
    //   cellDataGetter: ({ rowData }) => rowData?.organizationNumber,
    //   width: 100,
    //   sortable: true,
    // },
    {
      dataKey: 'edit',
      label: '',
      width: 200,
      flexGrow: 1,
      cellRenderer: ({ rowData: company }) => (
        <CompaniesMenu companyId={company.id} />
      ),
    },
  ].filter(Boolean)

  return columns
}

const CompaniesMenu = ({ companyId }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const onOpen = (event) => setAnchorEl(event.target)
  const onClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={onOpen} size="small">
        <MenuIcon />
      </IconButton>

      <Popover
        onClose={onClose}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        elevation={10}
        BackdropProps={{
          invisible: false,
        }}
      >
        <List dense>
          <a
            target="_BLANK"
            href={`${resolveUrl('frontend-company')}/companies/${companyId}`}
            className="no-underline text-black"
          >
            <ListItem button replace onClick={onClose}>
              <ListItemText primary={'Open in Company Admin'} />
            </ListItem>
          </a>
        </List>
      </Popover>
    </>
  )
}

const CompaniesBillingTable = ({ companies }) => {
  const [sortState, setSortState] = useState({
    sortOrder: 'billing.nextInvoice.amount',
    reverseSort: false,
  })
  const sorted = sortOn(
    companies,
    `${sortState.reverseSort ? '-' : ''}${sortState.sortOrder}`,
  )

  const columns = useColumns()
  return (
    <DataTable
      onSort={setSortState}
      {...sortState}
      data={sorted}
      columns={columns}
    />
  )
}

export default CompaniesBillingTable
