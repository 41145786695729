import { useState } from 'react'
import PageHeader from '~/components/Layout/components/PageHeader'
import SearchField from '~/components/Mui/SearchField'
import FuzzySearch from 'fuzzy-search'

import CompaniesBillingTable from '~/components/Companies/Table/billing'

import { gql, useQuery } from '@apollo/client'
import { CircularProgress, FormControlLabel, Switch } from '@material-ui/core'

const filterCompanies = (list = [], needle) => {
  if (!needle) return list

  const searcher = new FuzzySearch(
    list,
    ['name', 'country', 'organizationNumber'],
    {
      caseSensitive: false,
    },
  )

  const result = searcher.search(needle)

  return result
}

const GET_COMPANIES_WITH_BILLING_ENABLED = gql`
  query CompaniesWithBillingEnabled(
    $billingEnabled: Boolean
    $onboardingEnabled: Boolean
  ) {
    companies(
      billingEnabled: $billingEnabled
      onboardingEnabled: $onboardingEnabled
    ) {
      id
      name
      country
      organizationNumber
      placesCount(includeProjects: true, visibility: ACTIVE)
      itemsCount
      companyUsersCount
      createdAt
      createdBy {
        id
        name
        email
      }
      billingEnabled
      billing {
        status
        invoiceDetails {
          reference
          name
          email
        }
        nextInvoice {
          amount
          currency
        }
        equipment {
          pricing {
            current {
              type
              title
              usedPercentage
            }
          }
        }
      }
      onboardingEnabled
      onboardingChecklist(returnWholeCompanyProgress: true) {
        progress
        isDone
        steps {
          type
          isComplete
          isSkipped
        }
      }
    }
  }
`

const useCompaniesWithBillingEnabled = ({
  billingEnabled,
  onboardingEnabled,
}: {
  billingEnabled?: boolean
  onboardingEnabled?: boolean
}) => {
  const { data, loading } = useQuery(GET_COMPANIES_WITH_BILLING_ENABLED, {
    variables: {
      billingEnabled,
      onboardingEnabled,
    },
  })

  return {
    companies: data?.companies || [],
    loading,
  }
}

export default function CompaniesBillingDashboard() {
  const [includeWithoutBilling, setIncludeWithoutBilling] = useState(false)
  const { companies, loading } = useCompaniesWithBillingEnabled({
    billingEnabled: includeWithoutBilling ? undefined : true,
  })
  const [searchString, setSearchString] = useState('')
  const filteredCompanies = filterCompanies(companies, searchString)

  if (loading) return <CircularProgress />
  return (
    <>
      <PageHeader
        title="Companies billing+onboarding"
        toolbar={
          <>
            <FormControlLabel
              name="includeWithoutBilling"
              control={
                <Switch
                  checked={includeWithoutBilling}
                  onChange={(event, checked) =>
                    setIncludeWithoutBilling(checked)
                  }
                  color="primary"
                />
              }
              label="Include companies without billing"
            />
            <SearchField
              placeholder={'Search companies'}
              value={searchString}
              onChangeText={setSearchString}
              autoFocus
            />
          </>
        }
      />
      <CompaniesBillingTable companies={filteredCompanies} />
    </>
  )
}
