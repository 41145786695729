import { lazy, Suspense } from 'react'
import { Switch, Route, useLocation, useHistory } from 'react-router-dom'

import ItemDialog from '~/containers/Item/Dialog'

import { QueryParamProvider } from 'use-query-params'
import { DialogLoader } from './App/Loader'

const EditProjectDialog = lazy(() =>
  import('~/containers/Project/components/EditDialog'),
)

const getBackground = ({ state }) => {
  // This piece of state is set when one of the
  // gallery links is clicked. The `background` state
  // is the location that we were at when one of
  // the gallery links was clicked. If it's there,
  // use it as the location for the <Switch> so
  // we show the gallery in the background, behind
  // the modal.

  if (!state) return
  if (state.background) return state.background
  if (state.modal && state.from) return state.from
}

export default function ModalSwitch(props) {
  const location = useLocation()
  const history = useHistory()

  const background = getBackground(location)

  const mainLocation = background || location

  return (
    <>
      <Suspense fallback={<DialogLoader />}>
        {background && <Route path="/items/:itemId" component={ItemDialog} />}
        {background && (
          <Route
            path="/projects/:projectId/edit"
            component={EditProjectDialog}
          />
        )}
      </Suspense>
      <QueryParamProvider location={mainLocation} history={history}>
        <Switch location={mainLocation}>{props.children}</Switch>
      </QueryParamProvider>
    </>
  )
}
