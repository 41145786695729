import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  padded: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
  wrapper: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0),
  },
})

const LayoutWrapper = ({ classes, children, scrollable, slim, ...props }) => (
  <div
    className={slim ? classes.wrapper : classes.padded}
    css={
      scrollable
        ? {
            overflowY: 'scroll',
            overflowX: 'hidden',
            WebkitOverflowScrolling: 'touch',
          }
        : { overflow: 'hidden', flexShrink: 0 }
    }
    {...props}
  >
    {children}
  </div>
)

export default withStyles(styles)(LayoutWrapper)
