import Tooltip from '@material-ui/core/Tooltip'
import { MenuUp as ChevronUp, MenuDown as ChevronDown } from 'mdi-material-ui'

const SortIndicator = ({ sortDirection }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '5px',
      position: 'relative',
      right: '-5px',
    }}
  >
    <ChevronUp
      style={{
        color: sortDirection === 'DESC' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.2)',
        fontSize: '18px',
        marginBottom: '-10px',
      }}
    />
    <ChevronDown
      style={{
        color: sortDirection === 'ASC' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.2)',
        fontSize: '18px',
      }}
    />
  </div>
)

/**
 * Default table header renderer.
 */
export default function defaultHeaderRenderer(props) {
  const {
    dataKey,
    label,
    sortBy,
    sortDirection,
    disableSort,
    columnData = {},
  } = props

  const showSortIndicator = !disableSort && sortBy === dataKey

  let children = [
    <span
      className="ReactVirtualized__Table__headerTruncatedText"
      key="label"
      title={label}
    >
      {label}
    </span>,
  ]

  const tooltip = columnData && columnData.tooltip ? columnData.tooltip : label
  const isSorted = showSortIndicator && sortDirection

  // TODO: FIX https://github.com/mui-org/material-ui/issues/10914
  if (tooltip || (label && typeof label === 'string')) {
    children = [
      <Tooltip title={tooltip} key="tooltip" placement="top" enterDelay={300}>
        <div
          style={{
            position: 'relative',
            display: 'block',
            color: isSorted ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.9)',
            fontWeight: isSorted ? undefined : '400',
          }}
          className="ReactVirtualized__Table__headerTruncatedText"
        >
          <span>{label}</span>
        </div>
      </Tooltip>,
    ]
  }

  if (!disableSort && (showSortIndicator || columnData.sortable)) {
    children.push(
      <SortIndicator
        key="SortIndicator"
        sortDirection={showSortIndicator ? sortDirection : null}
      />,
    )
  }

  return children
}
