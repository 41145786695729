import { IconButton, Tooltip } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { LogoutVariant } from 'mdi-material-ui'

const LogoutButton = () => {
  return (
    <Tooltip title={'Sign out'}>
      <IconButton
        href={process.env.GATSBY_EQL_LOGIN + 'logout'}
        component="a"
        css={{ color: 'white' }}
      >
        <LogoutVariant />
      </IconButton>
    </Tooltip>
  )
}

export default LogoutButton
