import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import { Magnify as SearchIcon, Close as ClearIcon } from 'mdi-material-ui'

const SearchField = ({
  placeholder = 'Search...',
  value,
  onChangeText,
  onChange,
  fullWidth,
  autoFocus,
  name,
  ...restProps
}) => {
  const _onChange = (event) => {
    if (onChange) onChange(event)
    if (onChangeText) onChangeText(event.target.value)
  }
  return (
    <TextField
      value={value}
      onChange={_onChange}
      variant="outlined"
      autoFocus={autoFocus}
      fullWidth={fullWidth}
      placeholder={placeholder}
      name={name}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" disablePointerEvents>
            <SearchIcon style={{ color: '#999' }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={!value}
              onClick={() => _onChange({ target: { name, value: '' } })}
            >
              <ClearIcon style={{ opacity: value ? 1 : 0 }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  )
}

export default SearchField
